<template>
  <div class="flex flex-row flex-nowrap h-full w-full py-2 px-2 space-x-2 overflow-hidden" @mouseover="over = true" @mouseleave="over = false">
    <div class="w-32">
      <a v-yb-clipboard:text="query_id" v-tooltip="'Copy query id to clipboard'" class="yb-link">{{ query_id }}</a>
    </div>
    <div class="font-semibold">
      [{{ state }}]
    </div>

    <v-dropdown placement="left">
      <yb-highlight language="sql" :content="query_text" :wrap="false" class="overflow-hidden whitespace-pre-wrap max-h-8 w-full text-sm truncate" :class="over ? 'underline cursor-pointer' : ''" />
      <template #popper>
        <div class="inline-block rounded border border-yb-gray-lightest bg-white text-yb-gray-medium dark:bg-yb-black dark:text-yb-gray-faintest shadow-lg p-3 space-y-2 relative">
          <yb-close v-close-popper tiny />
          <yb-highlight language="sql" :content="query_text" class="overflow-hidden whitespace-pre-wrap text-sm truncate max-h-80 w-80" />

          <div class="w-full flex justify-end">
            <yb-button v-yb-clipboard:text="query_text" v-close-popper class="border border-yb-gray-faint justify-end" clipboard-label="(SQL)">
              <yb-icon class="yb-button-icon" icon="clipboard" />
              <translate>Copy to Clipboard</translate>
            </yb-button>
          </div>
        </div>
      </template>
    </v-dropdown>
  </div>
</template>

<script>
export default {
  data() {
    return {
      over: false
    }
  },
  computed: {
    query_id() {
      return this.params?.data?.query_id
    },
    query_text() {
      return this.params?.data?.query_text
    },
    state() {
      return this.params?.data?.state
    }
  }
}
</script>
