<template>
  <div class="yb-view">
    <yb-error class="yb-view-header" :show-error="populateError != null" :error-message="formatError(populateError)" @close="populateError = null" />
    <yb-grid
      v-if="instance"
      v-bind="$productionDataPrivate"
      ref="gridRuleResults"
      key="gridRuleResults"
      class="ag-theme-alpine yb-view-content"
      :rows="retrieveRuleResults"
      :row-height="32"
      :column-defs="ruleResultsColumnDefs"
      :grid-options="ruleResultsOptions"
      no-rows-to-show="No rule results to display"
      @ready="readyRuleResults"
      @select="selectRuleResults"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import debounce from 'debounce'

import RuleEvent from './RuleEvent.vue'
import RuleQueryText from './RuleQueryText.vue'
import { databaseObjectService } from '@/services'
import * as YbCellRenderers from '@/components/YbGridCellRenderers'
import { formatError } from '@/services/app.js'
import Instance from '@/models/Instance'

const YbRuleEventCellRenderer = defineComponent(RuleEvent)
const YbRuleQueryTextRowRenderer = defineComponent(RuleQueryText)

export default {
  props: {
    instanceId: {
      type: String,
      required: true
    },
    queryId: {
      type: Number,
      required: false
    },
    singleQuery: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      populateError: null,
      ruleResultsColumnDefs: [
        {
          headerName: 'ID',
          field: 'query_id',
          sortable: false,
          minWidth: 75,
          maxWidth: 150,
          flex: -1,
          hide: this.singleQuery,
          valueGetter: (params) => {
            return (!!params.data?.query_text && params.data?.query_id) || ''
          }
        },
        { headerName: 'Query', field: 'query_text', sortable: false, minWidth: 75, maxWidth: 150, flex: -1, hide: this.singleQuery },
        { headerName: 'State', field: 'state', sortable: false, minWidth: 75, maxWidth: 150, flex: -1, cellClass: 'center font-semibold', hide: !this.singleQuery },
        { headerName: 'Timestamp', field: 'event_time_ms', sortable: true, minWidth: 250, maxWidth: 250, flex: 0, cellRenderer: YbCellRenderers.YbGridTimestampUsCellRenderer },
        { headerName: 'Rule', field: 'rule_name', sortable: false, minWidth: 150, maxWidth: 250, flex: -1 },
        { headerName: 'Type', field: 'event_type', sortable: false, minWidth: 100, maxWidth: 150, flex: -1, cellRenderer: YbRuleEventCellRenderer },
        { headerName: 'Event', field: 'event', sortable: false, minWidth: 240, flex: 1 }
      ],
      ruleResultsOptions: {
        isFullWidthRow: (params) => {
          return !this.singleQuery && !!params?.rowNode?.data?.query_text
        },
        fullWidthCellRenderer: YbRuleQueryTextRowRenderer
      },
      selectedEvent: null
    }
  },
  computed: {
    instance() {
      return Instance.query().whereId([this.instanceId]).first()
    }
  },
  watch: {
    queryId(_) {
      this.refresh()
    }
  },
  beforeMount() {
    this.refresh = debounce(this.refreshRuleResults.bind(this), 1000)
  },
  methods: {
    formatError,
    refreshRuleResults() {
      this.$refs.gridRuleResults.reset()
    },
    async retrieveRuleResults(limit, offset, orderByModel, filterModel) {
      // Make the queries to get rule events and optionally query text.
      const whereSql = prefix => this.queryId ? `where ${prefix}.query_id = ${this.queryId}` : ``

      // We only support up/down sort on group_timestamp, so fake that out.
      let sort = 'desc'
      if (orderByModel.length > 1) {
        throw new Error('Can only sort on one column at a time')
      } else if (orderByModel.length === 1) {
        sort = orderByModel[0].sort
      }
      const orderBySql = `order by group_timestamp ${sort}, query_id ${sort}, group_index asc`

      // Do the event query.
      const queryRuleEvent = databaseObjectService.sql(this.instance.id, 'yellowbrick',
        `select
          (extract(epoch from re.event_time) * 1000)::numeric(18,3) as event_time_ms,
          re.*,
          case
            when re.group_index = 0
            then coalesce(qr.query_text, '(not available)')
            else null
          end as query_text
        from sys.query_rule_event re
        left outer join (select query_id, query_text from sys.query union all select query_id, query_text from sys.query_recent) qr
        on re.query_id = qr.query_id
        ${whereSql('re')}
        ${orderBySql}
        limit ${limit}
        offset ${offset}`)

      // Return rule events.
      const ruleEvents = (await queryRuleEvent).rows || []
      for (let index = 0; index < ruleEvents.length; ++index) {
        if (ruleEvents[index].query_text !== null) {
          // Capture and clobber query text on the event with query text.
          const { query_id, query_text } = ruleEvents[index]
          ruleEvents[index].query_text = null

          // Parse the rule event type.
          let state = 'unknown'
          const match = /Rule \[(.*?)\]/.exec(ruleEvents[index].event)
          if (match) {
            state = match[1]
          }

          // Graft in the rule event query_id/query_text placeholder row.
          ruleEvents.splice(index, 0, {
            query_id,
            query_text,
            state,
            hideQuery: !!this.singleQuery
          })
          ++index
        }
      }
      return ruleEvents
    },
    readyRuleResults() {
      this.$refs.gridRuleResults.gridColumnApi.applyColumnState({
        state: [
          {
            colId: 'event_time_ms',
            sort: 'desc'
          }
        ],
        defaultState: { sort: null }
      })
    },
    selectRuleResults(items) {
      if (!!items && !!items.length) {
        [this.selectedEvent] = items
      } else {
        this.selectedEvent = null
      }
    }
  }
}
</script>
