<template>
  <div class="flex flex-row flex-nowrap items-center space-x-2 h-full" :class="typeClass">
    <yb-icon v-if="typeIcon" class="w-4 block flex-grow-0 flex-shrink-0" :icon="typeIcon" />
    <div>{{ type }}</div>
  </div>
</template>

<script>
export default {
  computed: {
    type() {
      return this.params?.data?.event_type
    },
    typeIcon() {
      switch (this.type) {
        case 'begin':
          return 'day'
        case 'end':
          return 'night'
        case 'abort':
        case 'error':
          return 'error'
        case 'warn':
          return 'warning'
        case 'info':
        case 'debug':
          return 'info'
        case 'ignore':
          return 'hand'
        case 'disabled':
          return 'delete_circle'
        case 'set':
          return 'button_play'
        case 'throttle':
          return 'stop'
        case 'restart':
          return 'restart'
        case 'move':
          return 'truck'
        case 'timeout':
          return 'clock'
      }
      return null
    },
    typeClass() {
      switch (this.type) {
        case 'abort':
        case 'error':
          return 'text-red-500 text-semibold'
        case 'throttle':
          return 'text-red-700 dark:text-red-400'
        case 'restart':
        case 'move':
        case 'timeout':
          return 'text-pink-600 dark:text-pink-400'
        case 'warn':
          return 'text-yellow-700 dark:text-yellow-400'
        case 'info':
        case 'debug':
          return 'text-blue-600 dark:text-blue-300'
        case 'set':
          return 'text-green-600 dark:text-green-200'
        case 'disabled':
          return 'opacity-50'
      }
      return ''
    }
  }
}
</script>
